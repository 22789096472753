@import url('./css/spinKit.css');
@import url('./css/timePicker.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-sans text-15 text-gray-700;
  }
  button,
  input,
  textarea {
    @apply focus:outline-none;
  }

  * {
    @apply focus-visible:outline-none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}

body {
  margin: 0;
  font-family: 'Spoqa Han Sans Neo', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.some-wrapper .react-timekeeper {
  --main-box-shadow: none;
  --main-bg: white;
  --clock-wrapper-bg: white;
  --clock-bg: #f3f4f6;
  --top-text-color: #1f2937;
  --numbers-font-size-inner: 18px;
  --numbers-font-size-outer: 16px;
  --numbers-text-color: black;
  --top-selected-color: #0ea5e9;
  --hand-circle-center: #7dd3fc;
  --hand-circle-outer: #7dd3fc;
  --hand-minute-circle: #7dd3fc;
}

@font-face {
  font-family: 'BatangChe';
  src: local('BatangChe'), url('./fonts/BatangChe/BatangChe.ttf') format('truetype');
}

@layer components {
  .button {
    @apply flex cursor-pointer items-center justify-center whitespace-pre-line break-keep border p-2 text-sm font-bold shadow-smd;
  }
}

.ant-pagination-item-active {
  @apply border-apricot-600 !important;
}
.ant-pagination-item-active > a {
  @apply text-apricot-600 !important;
}
.ant-pagination-item-link-icon {
  @apply text-apricot-600 !important;
}
.center-box {
  @apply flex items-center justify-center;
}

[store-theme='apricot'] {
  --color-primary: theme('colors.apricot.600');
}

[store-theme='sky'] {
  /* --color-primary: theme('colors.sky.400'); */
  --color-primary: theme('colors.apricot.600');
}

/* 얇게 만들려는 스크롤바에 클래스 이름을 추가 */
.thin-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
}

/* input 자동완성 사용 시 생기는 하늘색 배경 제거 */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
  font-size: inherit;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* DateKeeper color custom */
.css-rt16tf-timeSelected-time {
  color: #c9906a !important;
}
.css-1igvx5z-timeSelected-time-TopBar {
  color: #c9906a !important;
}
.css-yxfhll-line {
  stroke: #c9906a !important;
}
.css-uby47j-centerCircle {
  fill: #c9906a !important;
}
.css-200z2t-outerCircle {
  fill: #c9906a !important;
}
