.react-ios-time-picker-hour {
  position: relative;
  width: 100px;
  overflow: hidden;
  z-index: 100;
  margin-right: 5px;
}

.react-ios-time-picker-minute {
  position: relative;
  width: 100px;
  overflow: hidden;
  z-index: 100;
  margin-left: 5px;
}

.react-ios-time-picker-hour-format {
  position: relative;
  width: 40px;
  overflow: hidden;
  z-index: 100;
}

.react-ios-time-picker-fast {
  transition: transform 150ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
}

.react-ios-time-picker-slow {
  transition: transform 150ms cubic-bezier(0.13, 0.67, 0.01, 0.94);
}

.react-ios-time-picker-top-shadow {
  position: absolute;
  top: 0;
  width: 100%;
  background: #0009;
  background: linear-gradient(180deg, #0009 0%, #1c1c1c 100%);
}

.react-ios-time-picker-bottom-shadow {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #0009;
  background: linear-gradient(0deg, #0009 0%, hsla(0, 0%, 11%, 1) 100%);
}

.react-ios-time-picker-cell-hour {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  transition: all 100ms linear;
  cursor: pointer;
}
.react-ios-time-picker-cell-minute {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  transition: all 100ms linear;
  cursor: pointer;
}
.react-ios-time-picker-cell-hour-format {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  user-select: none;
  transition: all 100ms linear;
}

.react-ios-time-picker-cell-inner-hour {
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #666;
  padding: 0 10px;
}

.react-ios-time-picker-cell-inner-hour-format {
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  color: #6a6a6b;
  padding: 0 10px;
}

.react-ios-time-picker-cell-inner-minute {
  width: fit-content;
  height: 100%;
  transition: all 100ms linear;
  cursor: pointer;
  border-radius: 7px;
  line-height: 35px;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #6a6a6b;
  padding: 0 10px;
}

.react-ios-time-picker-cell-inner-selected {
  /* font-weight: 500; */
  color: #f7f7f7;
  font-size: 24px;
}

.react-ios-time-picker-cell-inner-hour-format-selected {
  font-weight: 400;
  color: #f7f7f7;
  background-color: black;
}

.react-ios-time-picker-btn-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  z-index: 100;
}
.react-ios-time-picker-input {
  cursor: text;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid #0005;
}

.react-ios-time-picker-colon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #f7f7f7;
  position: relative;
  z-index: 100;
  font-weight: 600;
}

.react-ios-time-picker-cell-inner-hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.react-ios-time-picker-hour-format-transition {
  transition: transform 100ms ease-out;
}
